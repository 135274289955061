import { defineStore } from "pinia";

import { getCurrentMode } from "@/utils";

export const useUAParserStore = defineStore("ua-parser", {
	state: () => {
		return {
			mode: getCurrentMode(), // pc or mobile
			isBranchDialogVisible: false,
			isSampleDialogVisible: false,
		};
	},
	actions: {
		setMode(newMode) {
			this.mode = newMode;
		},
		setBranchDialogVisible(visible) {
			this.isBranchDialogVisible = visible;
		},
		setSampleDialogVisible(visible) {
			this.isSampleDialogVisible = visible;
		},
	},
});
