// import "./style.css";
import "@/assets/index.css";
import pinia from "@/store/index";
import { createApp } from "vue";
import App from "./App.vue";
/* 引入字体 */
import "./assets/css/font/font.css";
import router from "./router/index";

import "animate.css";

import {
	checkAndGetToken,
	checkTokenExpired,
	clearData,
	refreshToken,
} from "@/utils/index";
import { useUserInfoStore } from "./store/modules/useUserInfoStore";

//导入默认网络配置文件
// import config from "./config/net.config";

import microApp from "@micro-zoe/micro-app";

import userManager from "@/utils/sso.js";

// 引入vue-viewer
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

const app = createApp(App);

// 使用这个插件
app.use(VueViewer);

//导入i18n
import i18n from "./i18n";

microApp.start({
	lifeCycles: {
		created() {
			console.log("created 全局监听");
		},
		beforemount() {
			console.log("beforemount 全局监听");
		},
		mounted() {
			console.log("mounted 全局监听");
		},
		unmount() {
			console.log("unmount 全局监听");
		},
		error() {
			console.log("error 全局监听");
		},
	},
});

// // 开启预加载
microApp.preFetch([
	{
		name: "dnachron-vue2",
		url: `${import.meta.env.VITE_APP_BASE_URL}/legacy/`,
		level: 2,
	},
]);

// 监听子应用发送的消息
microApp.addDataListener("dnachron-vue2", async (data) => {
	console.log("子应用发送的消息111", data);
	if (data.userInfo) {
		useUserInfoStore().setUserInfo(JSON.parse(data.userInfo));
	}

	if (data.is_refresh && checkAndGetToken()) {
		try {
			if (await checkTokenExpired()) {
				await refreshToken();
			}
			// microApp.clearData("dnachron-vue2");
			return Promise.resolve(true);
		} catch (error) {
			// microApp.clearData("dnachron-vue2");
			useUserInfoStore().clearUserInfo();
			return Promise.reject(false);
		}
	}

	// 判断是否重定向到登录页
	if (data.is_redirect_login) {
		// 判断当前路由
		userManager.signinRedirect();
	}
});
// 监听子应用发送的消息
microApp.addDataListener("dnachron-vue2-component", async (data) => {
	console.log("子应用发送的消息222", data);
	try {
		if (data.is_refresh && checkAndGetToken()) {
			try {
				if (await checkTokenExpired()) {
					await refreshToken();
					// microApp.clearData("dnachron-vue2-component");
				}
				return Promise.resolve(true);
			} catch (error) {
				useUserInfoStore().clearUserInfo();
				// microApp.clearData("dnachron-vue2-component");
				return Promise.reject(false);
			}
		}
		// 判断是否需要清除数据并重定向到登录页
		if (data.is_redirect_login) {
			userManager.signinRedirect(); // 执行重定向
		}
		// 正常情况返回
		return true;
	} catch (error) {
		console.error("Error in data listener:", error);
		return false; // 出现错误时返回 false
	}
});

import { LangAndBasePathObj } from "@/utils/index";
microApp.setData("dnachron-vue2", {
	getLang: LangAndBasePathObj(
		import.meta.env.VITE_APP_SITE_ENV,
		window.location.origin,
	),
	// userManger: app.config.globalProperties.$userManager,
	userManger: userManager,
});

microApp.setData("dnachron-vue2-component", {
	getLang: LangAndBasePathObj(
		import.meta.env.VITE_APP_SITE_ENV,
		window.location.origin,
	),
	userManger: userManager,
});

microApp.setData("dnachron-vue2-sample", {
	getLang: LangAndBasePathObj(
		import.meta.env.VITE_APP_SITE_ENV,
		window.location.origin,
	),
	userManger: userManager,
});

microApp.setData("dnachron-vue2-branch", {
	getLang: LangAndBasePathObj(
		import.meta.env.VITE_APP_SITE_ENV,
		window.location.origin,
	),
	userManger: userManager,
});

// 监听页面的 visibilitychange 事件
document.addEventListener("visibilitychange", async () => {
	if (JSON.parse(localStorage.getItem("userInfo"))) {
		useUserInfoStore().setUserInfo(
			JSON.parse(localStorage.getItem("userInfo")),
		);
	} else {
		console.log(useUserInfoStore().userInfo, "这个userInfo是什么啊");
		// 判读userInfo是否存在
		if (Object.keys(useUserInfoStore().userInfo).length !== 0) {
			useUserInfoStore().clearUserInfo();
			location.reload();
		}
	}

	if (document.visibilityState === "visible") {
		if (checkAndGetToken()) {
			if (await checkTokenExpired()) {
				await refreshToken();
			}
		}
	}
});

app.use(i18n).use(router).use(pinia).mount("#app");
