const ar = {
	common: {
		login: "تسجيل الدخول",
		UserRegistration: "تسجيل مستخدم",
		ResetPassword: "إعادة تعيين كلمة المرور",
		Confirm: "تأكيد",
		Cancel: "إلغاء",
		Edit: "تعديل",
		PhoneNumber: "رقم الهاتف",
		Email: "البريد الإلكتروني",
		UserName: "اسم المستخدم",
		Password: "كلمة السر",
		VerificationCode: "رمز التحقق",
		ConfirmPassword: "تأكيد كلمة المرور",
		PleaseEnterThe: "الرجاء إدخال",
		EmailAlreadyExists:
			"There is already an account associated with the email ",
		PleaseLoginBindEmail:
			" Please log in and bind the third-party account in the personal center.",
		YouarevisitingDNAChronInternational:
			"You are visiting DNAChron International. We suggest you to visit DNAChron China according to your IP address.",
		Jumpto: "Jump to",
		Noprompt: "No prompt",
		yes: "yes",
		no: "no",
		Save: "حفظ . ",
		Wearesorrythepageyourequestedcannotbefound:
			"We are sorry, the page you requested cannot be found.",
		BacktoHome: "Back to Home",
	},
	navbar: {
		YTree: "شجرة Y",
		Ancestry: "النسب",
		ImportSample: " استيراد البيانات",
		Whatnews: "تجديد",
		SignIn: "تسجيل الدخول",
		SignUp: "تسجيل",
		Notification: "إخطار",
		PrivateMessage: "رسالة خاصة",
		AncestralTools: " المصدر أداة ",
		BrowseConfig: "تصفح التكوين",
		PersonalCenter: "المركز الشخصي",
		IndividualInformation: "معلومات الشخص الفاحص",
		SequenceData: "بيانات التسلسل",
		Account: "حساب",
		Logout: "خروج الدخول",
		TreeBrowseConfig: "Tree Browse Config",
		nodescountperpage: "nodes count per page",
		showdiscloseinformation: "Show Disclose Information",
		youcanviewyour:
			"You can view your private and authorized information after sign in.",
		showindividualname: "Show Individual First Name",
		showPopulation: "Show Population",
		showPopulationRule:
			"When this option is turned off, the population will still be displayed if the individual has no name information",
		language: "اللغة",
	},
	index: {
		Trace: "تتبع البصمة التاريخية للبشر",
		Tracethehuman:
			"انضم إلى المستخدمين حول العالم لتتبع رحلة الإنسان باستخدام أول شجرة Y T2T نقية في العالم، مع ضعف الدقة لتحليل بيانات تسلسل الجينات الخاصة بك وتحديد موقعك بدقة على شجرة Y البشرية.",
		Tracethehuman1:
			"انضم إلى المستخدمين حول العالم لتتبع رحلة الإنسان باستخدام أول شجرة",
		Tracethehuman2: " Y T2T ",
		Tracethehuman3:
			"نقية في العالم، مع ضعف الدقة لتحليل بيانات تسلسل الجينات الخاصة بك وتحديد موقعك بدقة على شجرة Y البشرية.",
		Tracethehuman4: "",
		Tracethehuman5: "",
		Joinnow: "استكشاف شجرة الأنساب الأبوية",
		Makeefficient: "الاستخدام الفعال لبيانات التسلسل",
		Ychromosomedata1:
			"يمكن لتحليل بيانات كروموسوم Y الاستخدام التكيفي لبيانات تسلسل تصل إلى 39 مليون، وهو ما يتجاوز بكثير النطاق التقليدي لتقدير العمر (8 مليون). يتيح ذلك تقديرًا أكثر دقة للعمر مع نطاقات احتمالية مفصلة عبر مختلف القطاعات. كلما زادت تغطية بيانات التسلسل، زادت دقة التقدير. بالنسبة لعينات التسلسل الجينومي الكامل أو الفروع، نستخدم أيضًا INDELs وMNPs لزيادة دقة التقدير.",
		Ychromosomedata2: "",
		Ychromosomedata3: "",
		Ychromosomedata4: "",
		Ychromosomedata5: "",
		Preciseanalysis: "تحليل دقيق",
		Basedon:
			"من خلال الاستخدام الفعال لبيانات التسلسل، يمكننا اكتشاف المزيد من الطفرات التي تساعد في توضيح هيكل كل مجموعة أحادية Y، خاصة للفروع التي تشهد توسعات كبيرة، مما يزيد بشكل كبير من دقة موقعك على شجرة Y. تستفيد خوارزمياتنا المتقدمة من جميع مناطق الكروموسوم Y، وخاصة المناطق الكبيرة من الهومولوجيات والهيتروكروماتين التي أُضيفت حديثًا في التسلسل المرجعي T2T، والتي غالبًا ما تكون بعيدة عن متناول الطرق التقليدية، مما يتيح لنا تحقيق دقة تحليل طفرات تزيد بمقدار الضعف. لدعم ذلك، نقدم خدمة مجانية لإعادة محاذاة بياناتك إلى التسلسل المرجعي T2T.",
		Faston: "تحليل سريع",
		Afterthe:
			"بعد تنزيل البيانات التي قدمها المستخدم بنجاح والتحقق من سلامتها، سنكمل التحليل في غضون 2-5 أيام عمل ونعرض النتائج على شجرة الفروع الأبوية، مما يقلل من وقت الانتظار ويساعدك في الحصول على النتائج بسرعة。",
		Convenientquery: "شجرة isogg المريحة للاستعلام",
		Fortheisogg:
			"بالنسبة إلى أرقام isogg المستخدمة في الأوراق ومؤسسات الاختبار ومؤسسات البحث العلمي ، وما إلى ذلك ، فإننا نقدم شجرة تمايز isogg واستعلام رقم لتلبية احتياجات الاستعلام الخاصة بك.",
		PrivacySecurity: "ضمان أمان الخصوصية",
		Wewill:
			"سنعمل على تخزين معلومات التسجيل الخاصة بك ومعلومات الاشخاص الفاحصين ، ومعلومات نتائج التحليل وبيانات المعلومات الجينية بشكل منفصل على السحابة ، ونحميها بشكل شامل من خلال تدابير أمنية معقولة وممكنة مثل عمليات التكنولوجيا والأجهزة والإدارة. ",
		exploreAncestral: "استكشاف الأنساب",
		ISOGGTree: "شجرة ISOGG",
		YTree: "شجرة Y",
		ImportData: "استيراد البيانات",
	},
	Footer: {
		FAQ: "مشكلة شائعة",
		Blog: "مدونة",
		CustomerService: "البريد الإلكتروني لخدمة العملاء",
		Officialcommunication: "Official communication group",
		AboutUs: "معلومات عنا",
		TermofService: "شروط الخدمة",
		PrivacyPolicy: "سياسة الخصوصية",
		ContactCustomerService: "اتصل بخدمة العملاء",
		ContactService: "Message Customer Service",
		InternationalSite: "International Site",
		ChinaSite: "China Site",
		Site: "موقع",
	},
	ytree: {
		FuzzySearch: " البحث غامض ",
		branchDetail: "تفاصيل الفروع",
		SnpOrISOgg: "SNP or ISOGG",
		HistoricalSearch: " تاريخ البحث",
		Clear: " إفراغ",
		nofind: "لا يوجد نتائج",
		YTree: '"شجرة Y"',
		InternationalSite: "International Site",
		ChinaSite: "China Site",
		PublicSample: "Public Sample",
		ResearchSamples: "عينات البحث",
		ResearchSamplesDescription:
			"الوصول إلى البيانات مقيد ولا يتاح للجمهور العام.",
		Contact: "مستخدم الاتصال",
		ContactMobile: "طريقة الاتصال",
		MyAncestry: "My Ancestry",
		YHaplogroup: "Y-Haplogroup",
		sequencequality: "Sequence Quality",
		CoverageStatus: "Coverage Status",
		MTHaplogroup: "MT-Haplogroup",
		originalID: "Original ID",
		Surname: "اللقب",
		Name: "الاسم",
		FirstName: "اللقب",
		BirthYear: "سنة الميلاد",
		c_and_c: "الدولة أو ثقافي",
		Period: "العصر",
		Location: "Location",
		Origin: "موطن المنشا",
		ArchaeologicalSite: "Archaeological Site",
		SiteName: "Site Name",
		Population: "جنسية",
		PopulationSubgroup: "مجموعة فرعية من السكان",
		NativeLanguage: "لغة محلية",
		Phone: "Phone",
		Source: "Source",
		SourceID: "Source ID",
		PersonalDNAChron: "الجينوم ",
		CoverageBase: "Covered Base",
		DNAChronDataOnT2T:
			"جميع البيانات في DNAChron تعمل على تسلسل مرجعي T2T. ونطاق البيانات يشير إلى تسلسلات المرجعية التي تغطيها البيانات الأولية الأصلية.",
		DataScope: "نطاق البيانات",
		TreeUncertainty: "Tree Uncertainty",
		nocover: "No Cover",
		possiblemerge: "possible merge",
		uncovermutation: "uncover mutation",
		Positive: "Positive",
		Negative: "Negative",
		Uncover: "Uncover",
		Avatar: "Avatar",
		ISOGG: "ISOGG",
		HaplogroupeName: "YTree",
		CoverageStatus: "Coverage Status",
		LongitudeAndLatitude: "Lat/Long",
		Period: "العصر",
		TMRCA: "زمن السلف المشترك",
		IndividualsCount: "الحجم الإجمالي للعينة",
		EstimatedAge: "تقدير العمر",
		AgesEstimateBases: "ages estimate bases",
		SourceNode: " عقدة المصدر ",
		Ifthesourcehaplogroupisupstream:
			"If the source haplogroup is upstream, the result of removing the influence of the current haplogroup is displayed. If there are other haplogroups between source haplogroup between source haplogroup / sample and current haplogroup, only the results of the regions that do not participate in the age estimation of the intermediate haplogroup but participate in the age estimation of the current haplogroup will be displayed.",
		CILength: "CI Length",
		TheShorterCI:
			"The shorter CI length is, the more influence to final result.",
		CoverageBase: "Covered Base",
		DNAChronDataOnT2T:
			"جميع البيانات في DNAChron تعمل على تسلسل مرجعي T2T. ونطاق البيانات يشير إلى تسلسلات المرجعية التي تغطيها البيانات الأولية الأصلية.",
		DataScope: "نطاق البيانات",
		TreeUncertainty: "شكل شجرة الممكن",
		treehasno: "Tree has no uncertainty",
		Mutations: "طفره",
		MutationCount: "Count: ",
		ToProtectUser:
			"الخاص أو فرع من كشف الإنسان قد تكون محمية . من أجل حماية خصوصية المستخدم ، في حين أن الطفرات التي قد تنتمي إلى القطاع",
		PossibleMutations: "ممكن طفره ",
		NoData: "لا تتوفر بيانات.",
		SubcladeName: "فرع：",
	},
	whatnews: {
		CreateDate: "Create Date",
		UpdateDate: "Update Date",
		YHaplogroup: "Y-HG",
		MTHaplogroup: "MT-HG",
		EstimatedAge: "تقدير العمر",
		Surname: "اللقب",
		Name: "الاسم",
		FirstName: "الاسم",
		BirthYear: "سنة الميلاد",
		Period: "ثقافي",
		Origin: "Origin",
		Location: "Location",
		SiteName: "Site",
		Population: "جنسية",
	},
	statusTips: {
		0: "نجاح",
		10010: "كلمة المرور خاطئة",
		10070: "اسم المستخدم غير صحيح",
	},
};

export default ar;
